import { getStatusCode } from '@readme/http-status-codes';
import React from 'react';

import './style.scss';

interface Props {
  className?: string;
  iconOnly?: boolean;
  includeMessage?: boolean;
  status: number | string | undefined;
}

const HTTPStatus = ({ className, status, iconOnly = false, includeMessage = false }: Props) => {
  if (status === undefined) {
    return null;
  }

  let theme = '1';

  if (Number(status) >= 500 || status === '5XX') theme = '5';
  else if (Number(status) >= 400 || status === '4XX') theme = '4';
  else if (Number(status) >= 300 || status === '3XX') theme = '3';
  else if (Number(status) >= 200 || status === '2XX') theme = '2';

  let statusCodeMessage;

  if (includeMessage) {
    try {
      statusCodeMessage = getStatusCode(status).message;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(`Error getting status code message: ${(error as Error).message}`);
    }
  }

  return (
    <code className={`${className || ''} HTTPStatus HTTPStatus_${theme}`}>
      <span aria-hidden={!iconOnly} aria-label={status.toString()} className="HTTPStatus-chit" role="img" />
      {!iconOnly && <span className="HTTPStatus-status">{status === 'default' ? 'Default' : status}</span>}
      {!!includeMessage && !!statusCodeMessage && <span>&nbsp;-&nbsp;{statusCodeMessage}</span>}
    </code>
  );
};

export default React.memo(HTTPStatus);
