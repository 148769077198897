import React, { useContext } from 'react';
import { Configure } from 'react-instantsearch-hooks-web';

import { SearchContext } from '../../context';

export default function ConfigurationWrapper() {
  const filters = [
    '(NOT isBodyEmpty:true OR type:endpoint)', // always filter out empty pages
    useContext(SearchContext).filters, // apply user filters from the modal UI
  ]
    .filter(Boolean)
    .join(' AND ');

  // @ts-ignore `filters` doesn't exist on react-instantsearch-hooks-web #fixme
  return <Configure filters={filters} />;
}
