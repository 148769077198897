import type { IProjectMetadata, ITabDefaults, ISearchContext } from './types';

import pick from 'lodash/pick';
import React, { createContext, useCallback, useState, useMemo } from 'react';

import useEnvInfo from '@core/hooks/useEnvInfo';

import { IndexNames, TabNames } from './types';

// Tab Definitions refer to the filters we send to Algolia based on tab selection
// I.e. If the guide tab is selected, we want to ensure we're filtering by Page AND not reference
const tabDefinitions = {
  [TabNames.All]: '',
  [TabNames.Guides]: 'indexName:Page AND isReference:false',
  [TabNames.Recipes]: 'indexName:Tutorial',
  [TabNames.Reference]: 'indexName:Page AND isReference:true',
  [TabNames.Changelog]: 'indexName:Blog',
  [TabNames.Discussions]: 'indexName:Discuss',
  [TabNames.CustomPages]: 'indexName:CustomPage',
};

const defaultTabs: ITabDefaults[] = [
  { moduleName: IndexNames.All, label: TabNames.All, icon: <i className="icon icon-search1" /> },
  { moduleName: IndexNames.Guides, label: TabNames.Guides, icon: <i className="icon icon-guides" /> },
  { moduleName: IndexNames.Recipes, label: TabNames.Recipes, icon: <i className="icon icon-recipes" /> },
  { moduleName: IndexNames.Reference, label: TabNames.Reference, icon: <i className="icon icon-references" /> },
  { moduleName: IndexNames.Changelog, label: TabNames.Changelog, icon: <i className="icon icon-changelog" /> },
  { moduleName: IndexNames.Discussions, label: TabNames.Discussions, icon: <i className="icon icon-discussions" /> },
  { moduleName: IndexNames.CustomPages, label: TabNames.CustomPages, icon: <i className="icon icon-custom-pages-2" /> },
];

/**
 * Converts a list of project schema shells into
 * an object that contains a key for the project and value for all enabled/disabled modules
 * for that project.
 * @returns {object} { developers: { guides: true, reference: false } }
 */
const enableModuleMap = (metadata: IProjectMetadata[]) => {
  return (metadata || []).reduce(
    (obj, { name, subdomain, modules }) => {
      obj[name || subdomain] = modules;
      return obj;
    },
    {} as Record<string, Record<string, boolean>>,
  );
};

/**
 * Consumes currently selected facets in sidebar, and enabled modules for the project or project group.
 * Converts values into an array of strings that refer to tabs that should be visible
 * @returns {array} ['guides', 'reference', 'changelogs']
 */
const buildAvailableTabs = (filters: string[], enabledModules: Record<string, Record<string, boolean>>) => {
  const selectedModules = filters?.length ? pick(enabledModules, filters) : enabledModules;

  const values = Object.values(selectedModules);
  const modules = Object.values(values).reduce((obj, module) => {
    Object.keys(module).forEach(key => {
      if (obj[key]) obj[key] = !!(obj[key] || module[key]);
      else obj[key] = !!module[key];
    });

    return obj;
  }, {});

  return Object.entries(modules)
    .filter(([, v]) => v)
    .map(([k]) => k);
};

/**
 * Consumes a list of visible tabs, and their alternate names.
 * @returns {array} Returns value similar to `defaultTabs()` on `line 18`
 */
const buildDisplayedTabs = (displayedTabs: string[], tabNames: Record<string, string>) => {
  return defaultTabs
    .filter(tab => {
      if (tab.moduleName === '') return true;
      return displayedTabs.includes(tab.moduleName as string);
    })
    .map(tab => {
      const altLabel = tabNames[tab.moduleName];
      if (altLabel) tab.altLabel = altLabel;
      return tab;
    });
};

export const SearchContext = createContext({
  activeTab: TabNames.All,
  displayedTabs: defaultTabs,
  filters: '',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  toggleFacetSelection: (facet: string) => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleTabChange: (tab: TabNames) => {},
});

export default function SearchContextWrapper({
  children,
  projectMetadata = [],
  handleKeyboardEvent,
  subdomain, // @todo move to project context when all projects are on hub3
}: ISearchContext) {
  const { isClient } = useEnvInfo();

  const [activeTab, setActiveTab] = useState(TabNames.All);
  const [filters, setFilters] = useState('');
  const [selectedFacets, setSelectedFacets] = useState(new Set<string>());

  // Determine modules enabled across all project(s). This should be an OR, not an AND (inclusive, not exclusive)
  const enabledModules = useMemo(() => enableModuleMap(projectMetadata), [projectMetadata]);

  // Search Tab Collection
  const displayedTabs: ITabDefaults[] = useMemo(() => {
    // Generate a list of tabs based on enabled project modules
    const availableTabs = buildAvailableTabs([...selectedFacets], enabledModules);
    // Determine any aliases for tab names based on project's configured nav_names
    const tabNames = projectMetadata.find((pm: IProjectMetadata) => pm.subdomain === subdomain)?.nav_names || {};
    // Construct a collection including labels and icon components for consumption
    return buildDisplayedTabs(availableTabs, tabNames);
  }, [selectedFacets, enabledModules, projectMetadata, subdomain]);

  // Handle Refinementlist / subdomain facet selection in right-hand modal sidebar
  // If facet exists in set, remove it. Otherwise, add it to set
  // We use this information to determine which modules to show based on a combined set of project selections
  const toggleFacetSelection = useCallback(
    (facet: string) => {
      if (selectedFacets.has(facet)) return setSelectedFacets(prev => new Set([...prev].filter(f => f !== facet)));
      return setSelectedFacets(prev => new Set(prev.add(facet)));
    },
    [selectedFacets],
  );

  // On selecting a different module tab (guides vs recipes, etc.) set the new tab to active and filter results
  const handleTabChange = useCallback(
    (tab: TabNames) => {
      /* Handle keyboard events */
      if (isClient && typeof tab === 'object') return handleKeyboardEvent(tab);
      /* Handle Click events */
      setFilters(tabDefinitions[tab]);
      return setActiveTab(tab);
    },
    [handleKeyboardEvent, isClient],
  );

  return (
    <SearchContext.Provider value={{ activeTab, displayedTabs, filters, toggleFacetSelection, handleTabChange }}>
      {children}
    </SearchContext.Provider>
  );
}
