/* eslint-disable no-useless-computed-key */
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { ProjectContext } from '@core/context';

import Button from '@ui/Button';
import Dropdown from '@ui/Dropdown';
import Menu, { MenuItem } from '@ui/Menu';

import classes from '../style.module.scss';
import { useLanguages } from '../utils/hooks';

const TranslationDropdown = ({ lang, langFull, version }) => {
  const { project } = useContext(ProjectContext);
  const languagesList = useLanguages({ lang, version });

  if (!(project.flags.translation && project.translate.show_widget)) return null;

  return (
    <Dropdown justify="start">
      <Button bem={{ ['slate_text']: true }} className={`${classes.NavItem} rm-Header-bottom-link`}>
        <span>{langFull}</span>
        <span className={`${classes['NavItem-chevron']} icon-chevron-down`} />
      </Button>
      <Menu>
        {languagesList.map(l => {
          if (!l) return null;
          return (
            <MenuItem key={l.name} active={lang === l.code} href={l.path} TagName="a">
              {l.name}
            </MenuItem>
          );
        })}
      </Menu>
    </Dropdown>
  );
};

TranslationDropdown.propTypes = {
  lang: PropTypes.string,
  langFull: PropTypes.string,
  version: PropTypes.object,
};

export default TranslationDropdown;
