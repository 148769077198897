/* eslint-disable no-useless-computed-key */
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import normalizeProjectStoreNavList from '@Hub/Header/utils/normalizeProjectStoreNavList';
import SearchTrigger from '@Hub/Search/Trigger';
import { ThemeContext } from '@Hub/Theme';

import { AppMetaContext, BaseUrlContext, ProjectContext, UserContext, EnterpriseParentContext } from '@core/context';
import useFeatureOptions from '@core/hooks/useFeatureOptions';
import useMediaQuery from '@core/hooks/useMediaQuery';
import useUniqueId from '@core/hooks/useUniqueId';
import useUserPermissions from '@core/hooks/useUserPermissions';
import { useProjectStore, useSuperHubStore } from '@core/store';
import JumpToContent from '@core/utils/JumpToContent';

import LandingHeader from '@routes/Landing/Header';

import Button from '@ui/Button';
import Dropdown from '@ui/Dropdown';
import Icon from '@ui/Icon';
import Menu, { MenuHeader } from '@ui/Menu';
import MenuItem from '@ui/Menu/Item';

import classes from '../../style.module.scss';
import { categoryIcons } from '../../utils/categoryIconMap';
import { useDarkMode, useLogo, useFeatureFilter } from '../../utils/hooks';
import MobileTopNav from '../mobile/TopNav';
import { isNavItemActive, NavLinkWrapper } from '../NavLinks';
import StagingBanner from '../StagingBanner';

const TopNavLinkItem = ({ item }) => {
  const { pathname } = useLocation();
  return (
    <NavLinkWrapper
      className={`rm-Header-link rm-Header-top-link Button_slate_text ${
        item.type !== 'url' && isNavItemActive(item, pathname) ? 'rm-Header-top-link_active' : ''
      } ${classes['Header-link']}`}
      item={item}
    >
      {item.text || item.type}
    </NavLinkWrapper>
  );
};

TopNavLinkItem.propTypes = {
  item: PropTypes.shape({
    text: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string,
  }),
};

const ThemeOverride = ({ setTheme }) => {
  const colorScheme = useDarkMode();
  const { colorMode, setColorMode } = useContext(ThemeContext);
  const [themeOverride, setThemeOverride] = useState(colorScheme);
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [visible, setVisible] = useState(false);
  const uid = useUniqueId('ThemeToggle');

  // set theme when toggled
  useEffect(() => {
    // except…when there’s a preview dark mode query param
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    const previewDarkMode = params.previewDarkMode;

    // hide until loaded
    if (colorMode === themeOverride) setVisible(true);

    if (!previewDarkMode) document.querySelector('[data-color-mode]').setAttribute('data-color-mode', themeOverride);
    localStorage.setItem('color-scheme', themeOverride);
    setColorMode(themeOverride);
    setTheme(themeOverride); // for header RDMD blocks (Owlbot)
  }, [colorMode, setColorMode, setTheme, themeOverride]);

  // set theme
  const toggleTheme = theme => setThemeOverride(theme);

  return (
    <Dropdown className={classes['ThemeToggle-wrapper']} trigger="contextMenu">
      <Button
        aria-label={`Toggle to ${themeOverride === 'light' ? 'dark' : 'light'} mode`}
        bem={{ ['slate_text']: true }}
        className={`rm-ThemeToggle ${classes.ThemeToggle} ${visible ? classes.ThemeToggle_active : ''}`}
        id={uid('button')}
        onClick={() => toggleTheme(themeOverride === 'light' ? 'dark' : 'light')}
        size="sm"
      >
        <svg
          aria-labelledby={uid('button')}
          className={`Icon ${classes['ThemeToggle-Icon']} ${
            colorMode === 'dark' || (colorMode === 'system' && prefersDarkMode)
              ? classes['ThemeToggle-Icon_active']
              : ''
          }`}
          role="img"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Moon (Dark Mode)</title>
          <path d="M19 14.79C18.8427 16.4922 18.2039 18.1144 17.1582 19.4668C16.1126 20.8192 14.7035 21.8458 13.0957 22.4265C11.4879 23.0073 9.74798 23.1181 8.0795 22.7461C6.41102 22.3741 4.88299 21.5345 3.67423 20.3258C2.46546 19.117 1.62594 17.589 1.25391 15.9205C0.881876 14.252 0.992717 12.5121 1.57346 10.9043C2.1542 9.29651 3.18083 7.88737 4.53321 6.84175C5.8856 5.79614 7.5078 5.15731 9.21 5C8.21341 6.34827 7.73385 8.00945 7.85853 9.68141C7.98322 11.3534 8.70386 12.9251 9.8894 14.1106C11.0749 15.2961 12.6466 16.0168 14.3186 16.1415C15.9906 16.2662 17.6517 15.7866 19 14.79Z" />
          <path
            className={classes['ThemeToggle-Icon-star']}
            d="M18.3707 1C18.3707 3.22825 16.2282 5.37069 14 5.37069C16.2282 5.37069 18.3707 7.51313 18.3707 9.74138C18.3707 7.51313 20.5132 5.37069 22.7414 5.37069C20.5132 5.37069 18.3707 3.22825 18.3707 1Z"
          />
        </svg>

        <svg
          aria-labelledby={uid('button')}
          className={`Icon ${classes['ThemeToggle-Icon']} ${
            colorMode === 'light' || (colorMode === 'system' && !prefersDarkMode)
              ? classes['ThemeToggle-Icon_active']
              : ''
          }`}
          role="img"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Sun (Light Mode)</title>
          <path className={classes['ThemeToggle-Icon-ray']} d="M12 1V3" />
          <path className={classes['ThemeToggle-Icon-ray']} d="M18.36 5.64L19.78 4.22" />
          <path className={classes['ThemeToggle-Icon-ray']} d="M21 12H23" />
          <path className={classes['ThemeToggle-Icon-ray']} d="M18.36 18.36L19.78 19.78" />
          <path className={classes['ThemeToggle-Icon-ray']} d="M12 21V23" />
          <path className={classes['ThemeToggle-Icon-ray']} d="M4.22 19.78L5.64 18.36" />
          <path className={classes['ThemeToggle-Icon-ray']} d="M1 12H3" />
          <path className={classes['ThemeToggle-Icon-ray']} d="M4.22 4.22L5.64 5.64" />
          <path d="M12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17Z" />
        </svg>
      </Button>
      <Menu>
        <MenuHeader>Color Scheme</MenuHeader>
        <MenuItem active={themeOverride === 'system'} icon="icon icon-monitor" onClick={() => toggleTheme('system')}>
          Same as System
        </MenuItem>
        <MenuItem active={themeOverride === 'light'} icon="icon icon-sun" onClick={() => toggleTheme('light')}>
          Light
        </MenuItem>
        <MenuItem active={themeOverride === 'dark'} icon="icon icon-moon" onClick={() => toggleTheme('dark')}>
          Dark
        </MenuItem>
      </Menu>
    </Dropdown>
  );
};

ThemeOverride.propTypes = {
  setTheme: PropTypes.func,
};

const TopNavUserItem = () => {
  const enableSuggestedEdits = useProjectStore(s => s.data.suggested_edits === 'enabled');
  const isSuperHubAdmin = useSuperHubStore(s => s.isSuperHubAdmin);
  const { permissions, user } = useContext(UserContext) || {};
  const baseUrl = useContext(BaseUrlContext);
  // TODO: Remove this!
  // https://linear.app/readme-io/issue/RM-1250
  const cleanBaseUrl = baseUrl === '/' ? '' : baseUrl;
  const { isAdminUser } = useUserPermissions();
  const { pathname } = useLocation();

  if (!permissions) {
    return (
      <Button
        bem={{ ['slate_text']: true }}
        className={`${classes['Header-link']} ${classes.NavItem} ${classes.NavItem_md} rm-Header-top-link rm-Header-top-link_login`}
        href={`/login?redirect_uri=${cleanBaseUrl}${pathname}`}
        target="_self"
      >
        Log In
      </Button>
    );
  }
  return (
    <Dropdown className="rm-Header-top-link_login" justify="end">
      <Button
        bem={{ ['slate_text']: true }}
        className={`${classes['Header-link']} ${classes.NavItem} ${classes.NavItem_md} rm-Header-top-link`}
      >
        <span>{user?.name || 'Account'}</span>
        <Icon aria-label="Chevron down" className={`${classes['NavItem-chevron']}`} name="chevron-down" size="md" />
      </Button>
      <Menu>
        {!!isAdminUser && !isSuperHubAdmin && (
          <MenuItem href={`${cleanBaseUrl}/dash`} icon="icon icon-lock1" TagName="a">
            Admin Panel
          </MenuItem>
        )}
        {!!enableSuggestedEdits && !isSuperHubAdmin && (
          <MenuItem href={`${cleanBaseUrl}/suggested-edits`} icon="icon icon-suggested-edits" TagName="a">
            Suggested Edits
          </MenuItem>
        )}
        <MenuItem href={`/logout?redirect_uri=${cleanBaseUrl}${pathname}`} icon="icon icon-power1" TagName="a">
          Log Out
        </MenuItem>
      </Menu>
    </Dropdown>
  );
};

const PageCategory = ({ appMeta, featureOptions }) => (
  <div className={classes['Header-left-nav']}>
    <i className={`${categoryIcons[appMeta?.type]} ${classes['Header-left-nav-icon']}`} />
    {featureOptions?.[appMeta?.type]}
    {appMeta?.type === 'custompage' && appMeta.title}
    {appMeta?.type === '404' && 'Page Not Found'}
  </div>
);

PageCategory.propTypes = {
  appMeta: PropTypes.shape({
    title: PropTypes.string,
    type: PropTypes.string,
  }),
  featureOptions: PropTypes.object,
};

const AppTopNav = ({ toggleSearch, lang, version, isDetachedProductionSite, setTheme }) => {
  const { project } = useContext(ProjectContext);
  const { appearance, name: projectTitle, modules } = project;
  const appMeta = useContext(AppMetaContext);
  const enterprise = useContext(EnterpriseParentContext);
  const { pathname, search } = useLocation();
  const featureOptions = useFeatureOptions(modules);
  const { src, whiteSrc, logoLink } = useLogo();
  const userScheme = useDarkMode();
  const [logoSrc, setLogoSrc] = useState(null);
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const isLP = ['/', ''].includes(pathname);
  const isLPWithSearch = isLP && appearance?.promos?.[0]?.extras?.type === 'search';
  const { parent } = project;

  const colorScheme = useProjectStore(s => s.data.appearance.brand.theme);
  const projectTheme = parent ? parent.appearance.colorScheme : colorScheme;
  const [headerType, logoSize] = useProjectStore(s => [s.data.appearance.header.type, s.data.appearance.logo.size]);

  const owlbotTrial = new URLSearchParams(search).get('owlbotTrial');
  const owlbotEnabled = project.owlbot.enabled || owlbotTrial === 'true';

  const [topNavRight, topNavLeft] = useProjectStore(s => [
    normalizeProjectStoreNavList(s.data.appearance.navigation.right),
    normalizeProjectStoreNavList(s.data.appearance.navigation.left),
  ]);

  const makeTopNavItems = (item, i) => {
    // TODO-HUB1-MIGRATION
    // Remove hub1 things from the topnav
    // We should do a migration to remove these once we remove hub1
    const filterTopNav = ['search', 'version', 'blog'];
    if (filterTopNav.includes(item.type)) return undefined;
    const TagType = item.type !== 'user' ? TopNavLinkItem : TopNavUserItem;
    return <TagType key={`tagtype-${item.type}-${i}`} item={item} />;
  };

  // the GLP for single project enterprises should use the nav items in the parent
  const topRightItems = isLP && project.flags.singleProjectEnterprise ? project.parent.topnav.right : topNavRight;
  const topLeftItems = isLP && project.flags.singleProjectEnterprise ? project.parent.topnav.left : topNavLeft;
  const leftTopNavItems = useFeatureFilter(topLeftItems).map(makeTopNavItems);
  const rightTopNavItems = useFeatureFilter(topRightItems).map(makeTopNavItems);

  // setting the initial theme for header RDMD blocks (only used for Owlbot atm)
  useEffect(() => setTheme(userScheme === 'system' ? (prefersDarkMode ? 'dark' : 'light') : userScheme));
  // determine correct logo file
  useEffect(() => {
    // If solid header, use primary logo
    // Else if the color scheme is light, uuse primary logo
    // Else use the white logo
    setLogoSrc(
      headerType !== 'line'
        ? src
        : userScheme === 'light' || (userScheme === 'system' && !prefersDarkMode)
          ? src
          : whiteSrc,
    );
  }, [headerType, prefersDarkMode, src, userScheme, whiteSrc]);

  return (
    <div className={`rm-Header-top ${classes['Header-top']}`}>
      <StagingBanner isDetachedProductionSite={isDetachedProductionSite} />
      <div className="rm-Container rm-Container_flex">
        <JumpToContent>
          <Button className={`rm-JumpTo ${classes['Header-jumpTo']}`} href="#content" target="_self">
            Jump to Content
          </Button>
        </JumpToContent>
        <div className={`rm-Header-left ${classes['Header-left']}`}>
          <a className={`rm-Logo ${classes['Header-logo']}`} href={logoLink} target="_self">
            <img
              alt={projectTitle}
              className={`rm-Logo-img ${logoSize === 'large' ? 'rm-Logo-img_lg' : ''} ${classes['Header-logo-img']}`}
              src={logoSrc || src}
            />
          </a>
          {leftTopNavItems}
        </div>
        <div className={`rm-Header-left ${classes['Header-left']} ${classes['Header-left_mobile']}`}>
          <MobileTopNav lang={lang} version={version} />
          <PageCategory appMeta={appMeta} featureOptions={featureOptions} />
        </div>
        <div className={`rm-Header-right ${classes['Header-right']}`}>
          <span className={classes['Header-right_desktop']}>{rightTopNavItems}</span>
          {projectTheme === 'system' && <ThemeOverride setTheme={setTheme} />}
          {!isLPWithSearch && !owlbotEnabled && (
            <SearchTrigger className={classes['Header-search']} toggleSearch={toggleSearch}>
              <div className="rm-SearchToggle-icon icon-search1" />
            </SearchTrigger>
          )}
        </div>
      </div>
      {!!isLP && !enterprise.isParent && <LandingHeader toggleSearch={toggleSearch} />}
    </div>
  );
};

AppTopNav.propTypes = {
  isDetachedProductionSite: PropTypes.bool.isRequired,
  lang: PropTypes.string,
  setTheme: PropTypes.func,
  toggleSearch: PropTypes.func,
  version: PropTypes.object,
};

export default AppTopNav;
