import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * React-router does not currently support re-setting focus on route change.
 * Focus will reset normally in Safari but this is neccessary to work on other browers
 * https://github.com/ReactTraining/react-router/issues/5210
 *
 * This utility allows us to wrap components to reset focus on them.
 * The focus reset will only run when the pathname changes or on initial load.
 */
const JumpToContent = ({ children }: { children: React.ReactNode }) => {
  const focusRef = useRef<HTMLDivElement>(null);
  const { pathname } = useLocation();
  const path = pathname;

  useEffect(() => {
    focusRef?.current?.focus({
      preventScroll: true,
    });
  }, [path]);

  return (
    <div ref={focusRef} style={{ outline: 'none' }} tabIndex={-1}>
      {children}
    </div>
  );
};

export default JumpToContent;
