import PropTypes from 'prop-types';
import React from 'react';

import Flyout from '@ui/Flyout';

import FlyoutMenu from './FlyoutMenu';
import classes from './style.module.scss';

const FlyoutButton = ({ onClick }) => {
  return (
    <button
      aria-label="Toggle navigation menu"
      className={`icon-menu ${classes.menu}`}
      onClick={onClick}
      type="button"
    />
  );
};

const MobileTopNav = ({ lang, version, setIsOpen }) => {
  return (
    <Flyout toggleButton={<FlyoutButton />}>
      <FlyoutMenu lang={lang} setIsOpen={setIsOpen} version={version} />
    </Flyout>
  );
};

FlyoutButton.propTypes = {
  onClick: PropTypes.func,
};

MobileTopNav.propTypes = {
  lang: PropTypes.string,
  setIsOpen: PropTypes.func,
  version: PropTypes.object,
};

export default MobileTopNav;
