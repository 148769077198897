import type { ProjectSchema as Project } from '@readme/backend/models/project/types';
import type { RefObject } from 'react';

import React, { useState, useCallback, useEffect } from 'react';
import { useSearchBox } from 'react-instantsearch-hooks-web';

import useClassy from '@core/hooks/useClassy';
import useDebounced from '@core/hooks/useDebounced';
import usePrevious from '@core/hooks/usePrevious';

import classes from './style.module.scss';

interface PromptProps {
  handleResultSelectionDebounced: (search: { term: string }) => void;
  onFocus: (e: React.FocusEvent<HTMLInputElement>) => void;
  project: Project;
  prompt: string;
  searchInput: RefObject<HTMLInputElement>;
  searching: boolean;
  setAskQuestion: (isAsking: boolean) => void;
  setIsTyping: (isTyping: boolean) => void;
  setPrompt: (prompt: string) => void;
  setSearching: (isSearching: boolean) => void;
}

const Prompt = ({
  project,
  prompt = '',
  setPrompt,
  searching,
  searchInput,
  onFocus: handleFocus,
  handleResultSelectionDebounced,
  setAskQuestion,
  setSearching,
  setIsTyping,
}: PromptProps) => {
  const bem = useClassy(classes, 'SearchBert-Prompt');
  const { refine, isSearchStalled } = useSearchBox();
  const prevPrompt = usePrevious(prompt); // The previous input value for the search box
  const [prevQuery, setPrevQuery] = useState(''); // The last query that was searched for

  const _onChange = useCallback(
    value => {
      refine(value);
      // We're about to get our search results, so set searching to false
      setSearching(false);
    },
    [refine, setSearching],
  );
  const onChange = useDebounced(_onChange, 300);

  const handleBlur = useCallback(
    event => {
      const { value: search } = event.currentTarget;

      if (project.subdomain && search !== prevQuery) {
        setPrevQuery(search);
        handleResultSelectionDebounced({ term: search });
      }
    },
    [project.subdomain, prevQuery, handleResultSelectionDebounced],
  );

  const handleChange = useCallback(
    e => {
      onChange(e.currentTarget.value);
      setPrompt(e.currentTarget.value);
    },
    [onChange, setPrompt],
  );

  const handleAskQuestion = useCallback(
    e => {
      if (e.key === 'Enter') {
        e.preventDefault();

        const hasQueryChanged = prompt !== prevQuery;
        if (prompt && hasQueryChanged) {
          setPrevQuery(prompt);
          setAskQuestion(true);
        }
      }
    },
    [prompt, prevQuery, setAskQuestion],
  );

  useEffect(() => {
    setSearching(isSearchStalled);
  }, [isSearchStalled, setSearching]);

  useEffect(() => {
    const isSearchStarted = !searching && prompt.length;
    // Detect when a user has started a new search query to kick off a "searching" state
    if (isSearchStarted && !prevPrompt) {
      setSearching(true);
    } else if (isSearchStarted && (!prevPrompt || prevPrompt !== prompt)) {
      // This triggers the spinner anytime the user types
      setIsTyping(true);
    }

    // Stop spinner on a timeout after the user has stopped typing
    const stopSpinning = setTimeout(() => {
      setIsTyping(false);
    }, 400);

    return () => clearTimeout(stopSpinning);
  }, [prevPrompt, prevQuery, prompt, searching, setIsTyping, setSearching]);

  useEffect(() => {
    if (prompt) onChange(prompt);
  }, [prompt, onChange]);

  return (
    <input
      ref={searchInput}
      autoCapitalize="off"
      autoComplete="off"
      autoCorrect="off"
      className={bem()}
      id="SearchBert"
      onBlur={handleBlur}
      onChange={handleChange}
      onFocus={handleFocus}
      onKeyDown={handleAskQuestion}
      placeholder="Search"
      spellCheck="false"
      tabIndex={0}
      type="search"
      value={prompt || ''}
    />
  );
};

export default Prompt;
