import PropTypes from 'prop-types';
import React, { useState, useMemo, useEffect } from 'react';

import SearchModal from '@Hub/Search/Modal';

import usePrevious from '@core/hooks/usePrevious';
import { useProjectStore } from '@core/store';
import classy from '@core/utils/classy';

import AppSubnav from './components/Subnav';
import AppTopNav from './components/TopNav';
import classes from './style.module.scss';
import './style.scss';

const AppNav = ({ search, isDetachedProductionSite, ...props }) => {
  const [theme, setTheme] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [toggleTargetElement, setToggleTargetElement] = useState(null);

  const [headerType, headerOverlayType, headerOverlayImageURL, headerOverlayFill, headerOverlayPosition] =
    useProjectStore(s => [
      s.data.appearance.header.type,
      s.data.appearance.header.overlay.type,
      s.data.appearance.header.overlay.image?.url,
      s.data.appearance.header.overlay.fill,
      s.data.appearance.header.overlay.position,
    ]);

  const toggleSearch = event => {
    setIsModalOpen(prevIsModalOpen => {
      // If the modal is opening, capture the element to return focus to when it closes
      if (!prevIsModalOpen && event && document.activeElement) {
        setToggleTargetElement(document.activeElement);
      }

      return !prevIsModalOpen;
    });
  };

  const previousIsModalOpen = usePrevious(isModalOpen);

  useEffect(() => {
    // If modal has just closed
    if (previousIsModalOpen && !isModalOpen) {
      // Return focus to the element that opened the modal
      if (toggleTargetElement) {
        toggleTargetElement.focus();
        setToggleTargetElement(null);
      }
    }
  }, [toggleTargetElement, previousIsModalOpen, isModalOpen]);

  const additionalHeaderClasses = classy(
    headerType === 'gradient' && 'rm-Header_gradient',
    headerType === 'overlay' && `rm-Header_overlay rm-Header_overlay-${headerOverlayType}`,
    headerType === 'overlay' &&
      headerOverlayType === 'custom' &&
      headerOverlayImageURL &&
      `rm-Header_custom rm-Header_custom-pos-${headerOverlayPosition} rm-Header_custom-size-${headerOverlayFill}`,
  );

  const headerStyling = useMemo(
    () =>
      headerType === 'overlay' && headerOverlayType === 'custom'
        ? { backgroundImage: `url(${headerOverlayImageURL})` }
        : {},
    [headerOverlayImageURL, headerOverlayType, headerType],
  );

  return (
    <header className={`${classes.Header} rm-Header ${additionalHeaderClasses}`} style={headerStyling}>
      <AppTopNav
        isDetachedProductionSite={isDetachedProductionSite}
        search={search}
        setTheme={setTheme}
        toggleSearch={toggleSearch}
        {...props}
      />
      <AppSubnav isModalOpen={isModalOpen} search={search} theme={theme} toggleSearch={toggleSearch} {...props} />

      <SearchModal
        appId={search.appId}
        email={search.email}
        indexName={search.indexName}
        isModalOpen={isModalOpen}
        projectsMeta={search.projectsMeta}
        searchApiKey={search.searchApiKey}
        toggleOpen={toggleSearch}
        urlManagerOpts={search.urlManagerOpts}
      />
    </header>
  );
};

AppNav.propTypes = {
  isDetachedProductionSite: PropTypes.bool.isRequired,
  lang: PropTypes.string,
  search: PropTypes.object, // PropTypes.shape(require('@Hub/Search/proptypes')),
  version: PropTypes.object,
};

AppNav.defaultProps = {
  version: {},
};

export default AppNav;
