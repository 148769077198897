import { VoteType, streamDelimiter } from '@readme/iso';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import classy from '@core/utils/classy';

import Button from '@ui/Button';
import CopyToClipboard from '@ui/CopyToClipboard';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import RDMD from '@ui/RDMD';

import classes from './style.module.scss';

const ChatGPT = ({ askQuestion, setAskQuestion, childSubdomain, prompt, project }) => {
  const [prevPrompt, setPrevPrompt] = useState(null);
  const [answer, setAnswer] = useState(null);
  const [loading, setLoading] = useState(false);
  const [doneReading, setDoneReading] = useState(false);
  const [vote, setVote] = useState(null);

  const handleVote = async direction => {
    setVote(direction);
    const res = await fetch(`${childSubdomain ? `/${childSubdomain}` : ''}/chatgpt/vote`, {
      body: JSON.stringify({ direction, prompt, answer, projectID: project._id }),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    });

    if (!res.ok) setVote(null);
  };

  async function processReader(reader, decoder) {
    const { value, done } = await reader.read();

    const chunkValues = decoder.decode(value).trim().split(streamDelimiter).filter(Boolean);
    for (let i = 0; i < chunkValues.length; i += 1) {
      const cv = chunkValues[i];

      if (cv.startsWith('error: ')) {
        // Sometimes there are false positives specifically ECONNRESET errors on Heroku.
        // So leave this out of the UI and just log them until we trust our errors more.

        // eslint-disable-next-line no-console
        console.error(cv);
      }

      if (cv.startsWith('data: ')) {
        setLoading(false);
        try {
          const text = cv.replace('data: ', '');
          setAnswer(prev => (prev ? prev + text : text));
        } catch (e) {
          // Sometimes weird data comes back from their api
        }
      }
    }

    if (done) {
      setDoneReading(true);
      return Promise.resolve();
    }

    return processReader(reader, decoder);
  }

  // http POST readme-docs-group.readme.local:3000/chatgpt/ask { question: hello }
  const ask = async question => {
    if (!question) return;

    setAnswer(null);
    setLoading(true);
    setDoneReading(false);

    const res = await fetch(`${childSubdomain ? `/${childSubdomain}` : ''}/chatgpt/ask`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ question }),
    });

    const data = res.body;

    setPrevPrompt(question);
    setAskQuestion?.(false);

    const reader = data.getReader();
    const decoder = new TextDecoder();
    await processReader(reader, decoder);
  };

  useEffect(() => {
    if (askQuestion) ask(prompt);
    // We don't want to add anything else to this dependency array;
    // Just to prevent ask getting called for anything other than enter being hit
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [askQuestion]);

  return (
    <div
      className={classy('rm-OwlbotAI-answer', classes['ChatGPT-answer'], classes['ChatGPT-answer_embed'])}
      data-color-mode="dark"
    >
      <div className={classy(classes.ChatGPT, classes['ChatGPT-form'])} data-testid="owlbotAI">
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label
          className={classy(classes['ChatGPT-orb-parent'], loading && classes['ChatGPT-orb-parent_loading'])}
          htmlFor="rm-OwlbotAI"
        >
          <div className={classes['ChatGPT-orb']}>
            <div className={classes['ChatGPT-orb-child']} />
          </div>
        </label>
        <Flex align="center" className={classes['ChatGPT-input']} gap="0.3em">
          {!loading && !prevPrompt && (
            <>
              Press <kbd className={classes['ChatGPT-input-kbd']}>Enter</kbd> to ask AI
            </>
          )}
          {!!loading && (
            <Flex gap="0">
              Thinking
              <span>
                <span className={loading ? classes['ChatGPT-input-ellipsis'] : ''}>.</span>
                <span className={loading ? classes['ChatGPT-input-ellipsis'] : ''}>.</span>
                <span className={loading ? classes['ChatGPT-input-ellipsis'] : ''}>.</span>
              </span>
            </Flex>
          )}
          {!loading && !!prevPrompt && <>{prevPrompt}</>}
        </Flex>
      </div>
      {!!answer && (
        <>
          <div className={classes['ChatGPT-answer-text']}>
            <Flex gap="sm" justify="start" layout="col">
              <RDMD className={classes['ChatGPT-answer-text-rdmd']}>{answer}</RDMD>
            </Flex>
          </div>
          {!!doneReading && (
            <div className={classes['ChatGPT-answer-voting']}>
              <Flex justify="between">
                <Flex gap="xs" justify="start">
                  <Button
                    aria-label="Thumbs up"
                    className={classes['ChatGPT-answer-btn']}
                    kind="contrast"
                    onClick={() => handleVote(VoteType.UP)}
                    size="xs"
                    text
                  >
                    <Icon
                      className={classy(
                        classes['ChatGPT-answer-thumb'],
                        vote === VoteType.UP ? classes['ChatGPT-answer-thumb-selected'] : '',
                      )}
                      name="thumbs-up"
                      style={{ '--icon-color': `${vote === VoteType.UP ? 'var(--green)' : 'var(--color-text-muted)'}` }}
                    />
                  </Button>
                  <Button
                    aria-label="Thumbs down"
                    className={classes['ChatGPT-answer-btn']}
                    kind="contrast"
                    onClick={() => handleVote(VoteType.DOWN)}
                    size="xs"
                    text
                  >
                    <Icon
                      className={classy(
                        classes['ChatGPT-answer-thumb'],
                        vote === VoteType.DOWN ? classes['ChatGPT-answer-thumb-selected'] : '',
                      )}
                      name="thumbs-down"
                      style={{ '--icon-color': `${vote === VoteType.DOWN ? 'var(--red)' : 'var(--color-text-muted)'}` }}
                    />
                  </Button>
                </Flex>
                <CopyToClipboard className={classes['ChatGPT-answer-clipboard']} text={answer} />
              </Flex>
            </div>
          )}
        </>
      )}
    </div>
  );
};

ChatGPT.propTypes = {
  askQuestion: PropTypes.bool,
  children: PropTypes.node,
  childSubdomain: PropTypes.string,
  project: PropTypes.object,
  prompt: PropTypes.string,
  setAskQuestion: PropTypes.func,
};

export default ChatGPT;
