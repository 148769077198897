import type { TabNames } from '../../types';

import React, { useCallback, useContext } from 'react';

import { SearchContext } from '../../context';

import classes from './style.module.scss';

const SearchTabs = () => {
  const { activeTab, displayedTabs, handleTabChange } = useContext(SearchContext);

  const handleKeyTabChange = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>, tab: TabNames) => {
      e.persist();
      // if not spacebar or enter
      if (e.keyCode !== 13 && e.keyCode !== 32) return null;
      return handleTabChange(tab);
    },
    [handleTabChange],
  );

  const handleClickTabChange = useCallback(
    (e: React.MouseEvent<HTMLDivElement>, tab: TabNames) => {
      e.persist();
      return handleTabChange(tab);
    },
    [handleTabChange],
  );

  return (
    <div className={classes.SearchTabs}>
      <div className="Tabs Tabs-list" role="tablist">
        {displayedTabs.map(tab => {
          const activeClass = activeTab === tab.label ? 'Tabs-listItem_active' : '';
          return (
            <div
              key={tab.label as string}
              aria-label={tab.label as string}
              aria-selected={activeTab === tab.label}
              className={[classes['SearchTabs-Tab'], 'Tabs-listItem', activeClass].join(' ')}
              onClick={e => handleClickTabChange(e, tab.label)}
              onKeyDown={e => handleKeyTabChange(e, tab.label)}
              role="tab"
              tabIndex={-1}
            >
              <span className={classes['SearchTabs-Tab']}>
                {tab.icon}
                {tab.altLabel || tab.label}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default React.memo(SearchTabs);
