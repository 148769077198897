import PropTypes from 'prop-types';
import React from 'react';

import './Trigger.scss';

const SearchTrigger = props => (
  <div className={props.className}>
    <button aria-label="Search" {...props.toggle} onClick={props.toggleSearch}>
      {props.children}
    </button>
  </div>
);

SearchTrigger.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  toggle: PropTypes.shape({
    className: PropTypes.string,
    'data-symbol': PropTypes.string,
  }),
  toggleSearch: PropTypes.func,
};

SearchTrigger.defaultProps = {
  children: 'Search',
  toggle: {
    className: 'rm-SearchToggle',
    'data-symbol': '⌘',
  },
};

export default SearchTrigger;
