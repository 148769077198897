/* eslint-disable no-useless-computed-key */
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { EnterpriseParentContext, ProjectContext, UserContext } from '@core/context';

import Button from '@ui/Button';
import Dropdown from '@ui/Dropdown';
import Menu, { MenuItem } from '@ui/Menu';

import classes from '../style.module.scss';
import { useSiblings } from '../utils/hooks';

const SiblingsLinks = ({ siblings }) => {
  return siblings.map(sib => (
    <Button key={sib.name} className={`${classes.NavItem} rm-Header-bottom-link`} href={sib.path}>
      <span>{sib.name}</span>
      {!!sib.current && <span className="icon-check1" />}
    </Button>
  ));
};

const SiblingsDropdown = ({ baseUrl, name, siblings }) => (
  <Dropdown justify="start" sticky>
    <Button
      bem={{ ['slate_text']: true }}
      className={`${classes.NavItem} Button Button_slate_text Button_md rm-Header-bottom-link rm-ProjectPicker`}
    >
      <span>{name}</span>
      <span className={`${classes['NavItem-chevron']} icon-chevron-down`} />
    </Button>
    <Menu className="rm-ProjectPicker-menu">
      {siblings.map(sib => {
        return (
          <MenuItem
            key={sib.name}
            active={baseUrl.split('/')[1] === sib.subdomain}
            href={sib.path || `/${sib.subdomain}`}
            TagName="a"
          >
            {sib.name}
          </MenuItem>
        );
      })}
    </Menu>
  </Dropdown>
);

const ProjectPicker = ({ baseUrl, lang }) => {
  const enterprise = useContext(EnterpriseParentContext);
  const siblings = useSiblings({ baseUrl, lang });

  const user = useContext(UserContext);
  const perms = user?.permissions?.map?.(p => p.project) || [];

  const { project } = useContext(ProjectContext);
  const { appearance, name } = project;

  let projects = enterprise.isParent ? enterprise.children : siblings;

  /* Only show public and appropriately permissioned projects.
   */ projects = projects?.filter(({ _id, internal }) => perms.includes(_id) || !internal);

  if (!projects || !projects.length) return null;

  if (enterprise.isParent) {
    if (appearance?.childrenAsPills) return <SiblingsLinks siblings={projects} />;
    return <SiblingsDropdown baseUrl={baseUrl} name={name} siblings={projects} />;
  }

  if (appearance?.subheaderStyle === 'dropdown' && projects.length > 1)
    return <SiblingsDropdown baseUrl={baseUrl} name={name} siblings={projects} />;

  return null;
};

SiblingsLinks.propTypes = {
  siblings: PropTypes.array,
};

SiblingsDropdown.propTypes = {
  baseUrl: PropTypes.string,
  name: PropTypes.string,
  siblings: PropTypes.array,
};

ProjectPicker.propTypes = {
  baseUrl: PropTypes.string,
  lang: PropTypes.string,
};

export default ProjectPicker;
