import React, { useEffect, useCallback, useState } from 'react';

import useClassy from '@core/hooks/useClassy';
import useDebounced from '@core/hooks/useDebounced';
import { useSearchStore } from '@core/store';

import Input from '@ui/Input';

import styles from './style.module.scss';

interface Props {
  inputRef: React.RefObject<HTMLInputElement>;
  isModalOpen: boolean;
  onClickout: ({ term }: { term: string }) => void;
  subdomain: string;
}

const SearchBox = ({ inputRef, isModalOpen, subdomain, onClickout }: Props) => {
  const bem = useClassy(styles, 'SearchBox');
  const [prevQuery, setPrevQuery] = useState('');
  const [isLoading, prompt, setPrompt] = useSearchStore(store => [store.isLoading, store.prompt, store.updatePrompt]);
  const [localPrompt, setLocalPrompt] = useState(prompt || '');

  // Update local prompt if prompt changes outside of this component (i.e. when closing modal)
  useEffect(() => {
    setLocalPrompt(prompt);
  }, [prompt]);

  // When the modal first opens, set focus to the searchbar
  useEffect(() => {
    if (isModalOpen && inputRef?.current) inputRef.current.focus();
  }, [inputRef, isModalOpen]);

  const debouncedOnChange = useDebounced(setPrompt, 300);

  const handleBlur = useCallback(
    event => {
      const { value: search } = event.currentTarget;

      if (subdomain && search !== prevQuery) {
        setPrevQuery(search);
        onClickout({ term: search });
      }
    },
    [subdomain, prevQuery, onClickout],
  );

  const handleChange = useCallback(
    e => {
      const newValue = e.currentTarget.value;
      setLocalPrompt(newValue);
      debouncedOnChange(newValue);
    },
    [setLocalPrompt, debouncedOnChange],
  );

  return (
    <div className={bem('&')}>
      <Input
        ref={inputRef}
        aria-label="Search"
        autoCapitalize="off"
        autoComplete="off"
        autoCorrect="off"
        className={bem('-input', isLoading && '-input_loading')}
        onBlur={handleBlur}
        onChange={handleChange}
        spellCheck="false"
        tabIndex={0}
        type="search"
        value={localPrompt}
      />
      {!!isLoading && <i className={bem('-spinner')} />}
    </div>
  );
};

export default React.memo(SearchBox);
