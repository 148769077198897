import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { NavLinkWrapper } from '@Hub/Header/components/NavLinks';
import { categoryIcons } from '@Hub/Header/utils/categoryIconMap';
import SearchTrigger from '@Hub/Search/Trigger';

import { ProjectContext } from '@core/context';
import useFeatureOptions from '@core/hooks/useFeatureOptions';
import useProjectPlan from '@core/hooks/useProjectPlan';
import { useSuperHubStore } from '@core/store';

import Flex from '@ui/Flex';
import RDMD from '@ui/RDMD';

import classes from './style.module.scss';

const LandingPageButton = ({ featureOptions, isPrimary = false, type }) => {
  const text = type === 'get-started' ? 'Get Started' : featureOptions[type];
  const featureType = type === 'get-started' ? 'docs' : type;
  return (
    <NavLinkWrapper
      className={`${
        isPrimary ? classes['LandingPageHeader-button_primary'] : classes['LandingPageHeader-button_secondary']
      }`}
      item={{ text, type: featureType }}
    >
      <i className={categoryIcons[featureType]} />
      <span>{text}</span>
    </NavLinkWrapper>
  );
};

LandingPageButton.propTypes = {
  featureOptions: PropTypes.object,
  isPrimary: PropTypes.bool,
  type: PropTypes.string,
};

const LandingHeader = ({ toggleSearch }) => {
  const isSuperHub = useSuperHubStore(s => s.isSuperHub);
  const { planFeatures } = useProjectPlan();
  const { project } = useContext(ProjectContext);
  const {
    appearance: { promos, html_promo: htmlPromo },
    modules,
  } = project;
  const featureOptions = useFeatureOptions(modules);
  const { title, text, extras } = promos?.[0] || {};
  const secondaryButton = extras?.buttonSecondary && extras?.buttonSecondary !== 'none';

  // For superhub only, hide the landing page header when it is restricted by
  // the current plan. This is b/c we're showing a pay gate banner in the
  // landing page content section and it doesn't make sense to gate both.
  if (isSuperHub && !planFeatures.landing_page) return null;

  if (extras?.type === 'none' && !title && !text) return <div></div>;

  return (
    <div className={`${classes['Header-landing']} container`}>
      {extras?.type === 'html' ? (
        <div dangerouslySetInnerHTML={{ __html: htmlPromo }} />
      ) : (
        <section className={`${classes.LandingPageHeader} rm-LandingPageHeader`}>
          <h1 className={classes['LandingPageHeader-title']}>{title}</h1>
          <RDMD className={classes['LandingPageHeader-text']}>{text}</RDMD>
          {extras?.type === 'buttons' && (
            <Flex align="center" justify="start">
              <LandingPageButton featureOptions={featureOptions} isPrimary type={extras?.buttonPrimary} />
              {!!secondaryButton && (
                <LandingPageButton featureOptions={featureOptions} type={extras?.buttonSecondary} />
              )}
            </Flex>
          )}
          {extras?.type === 'search' && (
            <SearchTrigger toggleSearch={toggleSearch}>
              <div className="icon-search1 rm-SearchToggle-icon" />
              <div className={`${classes['LandingPageHeader-search-placeholder']} rm-SearchToggle-placeholder`}>
                Search
              </div>
              <div className="rm-SearchToggle-shortcut">⌘K</div>
            </SearchTrigger>
          )}
        </section>
      )}
    </div>
  );
};

LandingHeader.propTypes = {
  toggleSearch: PropTypes.func,
};

export default LandingHeader;
