/* istanbul ignore file */
import type { IHitResult, IHitWithHandler, ISearchResults } from './types';
import type { SearchResults as SearchResultsType } from '@readme/api/src/mappings/search/types';
import type { $TSFixMe } from '@readme/iso';

import React, { useCallback, useMemo } from 'react';
import { Snippet, useHits } from 'react-instantsearch-hooks-web';

import { UrlManager } from '@core/context';
import useEnvInfo from '@core/hooks/useEnvInfo';
import useReadmeApi from '@core/hooks/useReadmeApi';
import { useSearchStore, useSuperHubStore } from '@core/store';
import classy from '@core/utils/classy';

import APIMethod from '@ui/API/Method';
import Icon from '@ui/Icon';
import Title from '@ui/Title';

import SearchResultsSuperHub from '../../../SuperHubSearch/SearchResults';
import useHitUrl from '../../core/useHitUrl';
import Pagination from '../Pagination';

import classes from './style.module.scss';

const HitIcon = ({ className, hit }: { className?: string; hit: IHitResult }) => {
  const { indexName, isReference } = hit;

  const iconMap = useMemo(
    () =>
      new Map(
        Object.entries({
          'Page-Ref': 'icon-references',
          'Page-Guide': 'icon-guides',
          Tutorial: 'icon-recipes',
          Blog: 'icon-changelog',
          Discuss: 'icon-discussions',
          CustomPage: 'icon-custom-pages-2',
        }),
      ),
    [],
  );

  const key = indexName !== 'Page' ? indexName : `Page-${isReference ? 'Ref' : 'Guide'}`;
  const iconClass = iconMap.get(key);

  return iconClass ? (
    <i className={classy(`${className}-result-icon`, classes['SearchResults-Result-Icon'], iconClass)} />
  ) : null;
};

export const Hit = ({ className, hit, projectMetadata, onResultSelection, urlManager }: IHitWithHandler) => {
  // Build Response URL
  const hitUrl = useHitUrl({ hit, projectMetadata, urlManager });

  // Determine which attribute we should be including in the higlighted row
  // Ideally, we pull a value that has a "full" match, and is different than the existing
  // title we display.
  // Possible values are `title | body | subheader | excerpt`
  const attribute = useMemo(() => {
    const { _snippetResult: snippet } = hit;

    const fullMatch = Object.entries(snippet).sort((a, b) => {
      const [aAttr, aVal] = a;
      const [, bVal] = b;

      if (aVal.matchLevel === 'full' && (bVal.matchLevel === 'partial' || bVal.matchLevel === 'none')) return -1;
      else if (aVal.matchLevel === bVal.matchLevel && aAttr === 'title') return 1;
      else if (aVal.matchLevel === bVal.matchLevel) return 0;
      return 1;
    });

    return fullMatch[0][0];
  }, [hit]);

  const title = hit.isReference && hit.method ? `(${hit.method.toUpperCase()}) ${hit.title}` : hit.title;

  const handleArrowFocus = useCallback((e: React.KeyboardEvent<HTMLAnchorElement>) => {
    const { key, target } = e;
    const isValidArrowKey = ['ArrowUp', 'ArrowDown'].includes(key);
    if (!isValidArrowKey) return;

    // Stupid TS BS
    // parentElement, etc. don't exist on Keyboard events
    const castTarget = target as unknown as $TSFixMe;

    const hitList = castTarget.parentElement;
    const nextFocusedEl =
      key === 'ArrowUp'
        ? castTarget?.previousElementSibling || hitList?.lastElementChild
        : castTarget?.nextElementSibling || hitList?.firstElementChild;

    nextFocusedEl?.focus();
    e.preventDefault();
  }, []);

  return (
    <a
      key={hit.objectID}
      className={classy(`${className}-result`, classes['SearchResults-Result'])}
      href={hitUrl}
      onClick={() => onResultSelection({ link: hitUrl })}
      onKeyDown={e => handleArrowFocus(e)}
    >
      <HitIcon className={className} hit={hit} />
      <div className={classes['SearchResults-Result-Text']}>
        <header className={classes['SearchResults-Result-Header']} title={title}>
          {!!hit.isReference && hit.type === 'endpoint' && !hit.discriminator && (
            <APIMethod className={classes['SearchResults-Method']} type={hit.method} />
          )}
          <span className={classy(classes['SearchResults-Result-Title'], `${className}-result-title`)}>
            {hit.title}
            {projectMetadata.length > 1 && (
              <span className={classy(classes['SearchResults-Result-Project'], `${className}-result-project`)}>
                {hit.subdomain}
              </span>
            )}
          </span>
          {!!hit.link_url && (
            <small className={classes['SearchResults-Result-Link']}>
              <i className="fa fa-external-link-alt" />
            </small>
          )}
        </header>
        <div className={classy(classes['SearchResults-Result-Excerpt'], `${className}-result-excerpt`)} title={title}>
          <Snippet attribute={attribute} hit={hit as unknown as $TSFixMe} />
        </div>
      </div>
    </a>
  );
};

const SearchResults = ({ className, onResultSelection, projectMetadata, urlManagerOpts, limit }: ISearchResults) => {
  const { isClient } = useEnvInfo();
  const [prompt, sectionFilter] = useSearchStore(store => [store.prompt, store.sectionFilter]);
  const { hits: allHits, results } = useHits();
  const [isSuperHub, apiBaseUrl] = useSuperHubStore(s => [s.isSuperHub, s.apiBaseUrl]);

  const {
    data: resultsData = {
      data: [],
      total: 0,
    },
  } = useReadmeApi<SearchResultsType>(
    isSuperHub && prompt
      ? `${apiBaseUrl}/search?query=${prompt}${sectionFilter ? `&section=${sectionFilter}` : ''}`
      : null,
  );

  if (isSuperHub && resultsData.total > 0) {
    return (
      <SearchResultsSuperHub
        className={className}
        isSearchBert
        limit={limit}
        onResultSelection={onResultSelection}
        projectMetadata={projectMetadata}
        results={resultsData}
      />
    );
  }

  let hits = allHits;

  const { query } = results as unknown as { query: string };

  if (!query) {
    return (
      <div className={['rm-SearchModal-empty', classes.SearchResults, classes.SearchResults_empty].join(' ')}>
        {/* Keeping `icon icon-search` classes for custom CSS */}
        <Icon className="rm-SearchModal-empty-icon icon icon-search" name="search" size="md" />
        <Title className="rm-SearchModal-empty-text" level={6}>
          {prompt.length ? 'Keep' : 'Start'} typing to search…
        </Title>
      </div>
    );
  }

  const opts = {
    /**
     * Construct the UrlManager instance from the
     * global options hash exposed in our router.
     * @see  https://git.io/JYUA3
     * @todo We can + should remove this once we
     *       nix Angular and/or the UI directive!
     */
    ...(isClient && (window as unknown as $TSFixMe)?._UrlManagerOptions),
    // React options using the project context - can use just this once angular is gone!
    ...urlManagerOpts,
  };
  const urlManager = new UrlManager(opts);

  if (hits.length === 0) {
    return (
      <div className={classy(classes.SearchResults, classes.SearchResults_empty, `${className}-empty`)}>
        <div className={`${className}-empty-description`}>No search results for ‘{query}’.</div>
      </div>
    );
  }

  if (limit) hits = hits.slice(0, limit);

  return (
    <div className={classy(className, [classes.SearchResults, classes.SearchResults_default].join(' '))}>
      <div className={classy(`${className}-scrollTo`, classes['SearchResults-ScrollTo'])} />
      <div
        aria-live="polite"
        className={classy(`${className}-list`, 'SearchResults-list', classes['SearchResults-list'], 'notranslate')}
      >
        {hits.map(hit => (
          <Hit
            key={hit.objectID}
            className={className}
            hit={hit as unknown as IHitResult}
            onResultSelection={onResultSelection}
            projectMetadata={projectMetadata}
            urlManager={urlManager}
          />
        ))}
      </div>
      {!limit && <Pagination />}
    </div>
  );
};

export default React.memo(SearchResults);
