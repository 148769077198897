import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { ProjectContext } from '@core/context';
import useMediaQuery from '@core/hooks/useMediaQuery';

import headerClasses from '../../style.module.scss';
import { useDarkMode, useLogo, useFeatureFilter } from '../../utils/hooks';

import {
  MobileLinkItem,
  MobileUserItem,
  MenuItemsMobile,
  VersionPickerMobile,
  TranslationPickerMobile,
  ProjectPickerMobile,
} from './components';
import classes from './style.module.scss';

const FlyoutMenu = ({ lang, setIsOpen, version }) => {
  const { project } = useContext(ProjectContext);
  const { parent } = project;
  const { src, whiteSrc, logoLink } = useLogo();
  const { pathname } = useLocation();

  const isLP = ['/', ''].includes(pathname);
  const isGLP = isLP && project.childrenProjects?.length;
  const isSingleProjectGLP = parent && isLP;

  const makeBottomItems = (item, i) =>
    item.type === 'user' ? (
      <MobileUserItem key={`bottom-mobile-${item.type}-${i}`} item={item} />
    ) : (
      <MobileLinkItem key={`bottom-mobile-${item.type}-${i}`} item={item} setIsOpen={setIsOpen}>
        {item.text}
      </MobileLinkItem>
    );

  const topLeftNav = isSingleProjectGLP ? parent.topnav.left : project.topnav.left;
  const topRightNav = isSingleProjectGLP ? parent.topnav.right : project.topnav.right;

  const leftTopNavItems = useFeatureFilter(topLeftNav).map(makeBottomItems);
  const rightTopNavItems = useFeatureFilter(topRightNav).map(makeBottomItems);

  const showVersion = project.appearance.showVersion && !!Object.keys(version).length && !isGLP;
  const showTranslation = project.flags.translation && project.translate.show_widget;
  const showSiblings =
    !project.flags.singleProjectEnterprise &&
    project?.siblings?.length &&
    project.appearance.subheaderStyle !== 'links';

  // determine correct logo file
  const { appearance } = project;
  const userScheme = useDarkMode();
  const [logoSrc, setLogoSrc] = useState(null);
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  useEffect(() => {
    // If solid header, use primary logo
    // Else if the color scheme is light, use primary logo
    // Else use the white logo
    setLogoSrc(
      appearance.theme !== 'line'
        ? src
        : userScheme === 'light' || (userScheme === 'system' && !prefersDarkMode)
          ? src
          : whiteSrc,
    );
  }, [appearance.theme, prefersDarkMode, src, userScheme, whiteSrc]);

  return (
    <div className={classes.MobileFlyout}>
      <MenuItemsMobile setIsOpen={setIsOpen} />
      {!!showTranslation && <TranslationPickerMobile lang={lang} version={version} />}
      {!!showSiblings && <ProjectPickerMobile lang={lang} />}
      {!!showVersion && <VersionPickerMobile lang={lang} version={version} />}
      <hr className={classes['MobileFlyout-divider']} />
      {leftTopNavItems}
      {rightTopNavItems}
      <a
        className={`${classes['MobileFlyout-logo']} ${headerClasses['Header-logo']} rm-Logo`}
        href={logoLink}
        target="_self"
      >
        <img alt={project.name} className={`${headerClasses['Header-logo-img']} rm-Logo-img`} src={logoSrc || src} />
      </a>
    </div>
  );
};

FlyoutMenu.propTypes = {
  lang: PropTypes.string,
  setIsOpen: PropTypes.func,
  version: PropTypes.object,
};

export default FlyoutMenu;
