import React from 'react';
import { usePagination } from 'react-instantsearch-hooks-web';

import classes from './style.module.scss';

const Pagination = () => {
  const { currentRefinement, nbPages, refine } = usePagination();
  const current = currentRefinement + 1;

  if (nbPages <= 1) return null;

  const handlePageChange = (e: React.MouseEvent<HTMLButtonElement>, i: number) => {
    refine(currentRefinement + i);
    e.stopPropagation();
  };

  return (
    <footer className={classes.SearchPagination}>
      {current !== nbPages && (
        <button
          className={`Button Button_sm Button_secondary_ghost Button_secondary ${classes['SearchPagination-Button']}`}
          onClick={e => handlePageChange(e, 1)}
          type="button"
        >
          Next
        </button>
      )}
      <span className={classes['SearchPagination-State']}>
        {current} of {nbPages}
      </span>
      {current !== 1 && (
        <button
          className={`Button Button_sm Button_secondary_ghost Button_secondary ${classes['SearchPagination-Button']}`}
          onClick={e => handlePageChange(e, -1)}
          type="button"
        >
          Back
        </button>
      )}
    </footer>
  );
};

export default React.memo(Pagination);
