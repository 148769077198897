export const categoryIcons = {
  changelog: 'icon-changelog',
  custompage: 'icon-file',
  discuss: 'icon-discussions',
  docs: 'icon-guides',
  graphql: 'icon-graphql1',
  home: 'icon-landing-page-2',
  landing: 'icon-landing-page-2',
  logs: 'icon-apilogs',
  recipes: 'icon-recipes',
  reference: 'icon-references',
  tutorials: 'icon-recipes',
};
