import type { SearchTab } from '../types';

import React, { useCallback } from 'react';

import { IndexNames, TabNames } from '@Hub/Search/types';

import useClassy from '@core/hooks/useClassy';
import { useProjectStore, useSearchStore } from '@core/store';

import Icon from '@ui/Icon';

import styles from './style.module.scss';

export const allTabs: SearchTab[] = [
  {
    moduleName: IndexNames.All,
    label: TabNames.All,
    icon: <Icon className="icon" name="search" />,
  },
  {
    moduleName: IndexNames.Guides,
    // 'guides' = 'docs' in the search API
    searchModuleName: 'guides',
    moduleLabelName: 'guides',
    label: TabNames.Guides,
    icon: <Icon className="icon" name="book-open" />,
  },
  {
    moduleName: IndexNames.Recipes,
    // 'tutorials' = 'recipes' in the search API
    searchModuleName: 'recipes',
    moduleLabelName: 'recipes',
    label: TabNames.Recipes,
    icon: <Icon className="icon" name="recipes" />,
  },
  {
    moduleName: IndexNames.Reference,
    label: TabNames.Reference,
    icon: <Icon className="icon" name="code" />,
  },
  {
    moduleName: IndexNames.Changelog,
    label: TabNames.Changelog,
    icon: <Icon className="icon" name="megaphone" />,
  },
  {
    moduleName: IndexNames.Discussions,
    label: TabNames.Discussions,
    moduleLabelName: 'discussions',
    icon: <Icon className="icon" name="message-circle" />,
  },
  {
    moduleName: IndexNames.CustomPages,
    // 'custompages' = 'custom_pages' in the search API
    searchModuleName: 'custom_pages',
    label: TabNames.CustomPages,
    icon: <Icon className="icon" name="custom-pages" />,
  },
];

const SearchTabs = () => {
  const bem = useClassy(styles, 'SearchTabs');

  const [activeTab, displayedTabs, setTab] = useSearchStore(store => [
    store.activeTab,
    store.displayedTabs,
    store.setTab,
  ]);

  const projectNavLinks = useProjectStore(s => s.data.appearance.navigation.links);

  const handleKeyTabChange = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>, tab: SearchTab) => {
      e.persist();
      // if not spacebar or enter
      if (e.key !== 'Enter' && e.key !== ' ') return null;
      return setTab(tab);
    },
    [setTab],
  );

  const handleClickTabChange = useCallback(
    (e: React.MouseEvent<HTMLDivElement>, tab: SearchTab) => {
      e.persist();
      return setTab(tab);
    },
    [setTab],
  );

  return (
    <div className={bem('&')}>
      <div className="Tabs Tabs-list" role="tablist">
        {allTabs.map(tab => {
          // Always display the All and Custom Pages tabs, for everything else
          // only display it's an enabled module from project metadata
          if (
            tab.moduleName !== IndexNames.All &&
            tab.moduleName !== IndexNames.CustomPages &&
            !displayedTabs.includes(tab.moduleName)
          ) {
            return null;
          }

          const navlink = projectNavLinks[tab.moduleLabelName || tab.moduleName];

          const activeClass = activeTab === tab.label ? 'Tabs-listItem_active' : '';

          return (
            <div
              key={tab.label as string}
              aria-label={tab.label as string}
              aria-selected={activeTab === tab.label}
              className={bem('-tab', 'Tabs-listItem', activeClass)}
              onClick={e => handleClickTabChange(e, tab)}
              onKeyDown={e => handleKeyTabChange(e, tab)}
              role="tab"
              tabIndex={-1}
            >
              <span className={bem('-tab')}>
                {tab.icon}
                {navlink?.alias || tab.label}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default React.memo(SearchTabs);
