import type { IHit } from '../components/SearchResults/types';
import type { $TSFixMe } from '@readme/iso';

import { useContext, useMemo } from 'react';

import { EnterpriseParentContext } from '@core/context';

export default function useHitUrl({ hit, projectMetadata, urlManager }: IHit) {
  const { isParent, singleParent } = useContext(EnterpriseParentContext);
  const { project, internalLink, versionPath } = hit;

  // Determine subdomain of result by mapping `project._id` (on result) to subdomain reference
  const resultSubdomain = useMemo(() => {
    const { subpath, subdomain } = projectMetadata?.find(({ id }) => id?.toString() === project?.toString()) ?? {};
    return subpath || subdomain;
  }, [projectMetadata, project]);

  // Are we on a single project enterprise?
  const isSingleProjectEnterpise = !!(singleParent || urlManager.options.hasOneChild);

  // Are we on a enterprise group? We'll know if `isParent` is in context
  // Or the current project's parent is populated
  const isEnterpise = useMemo(() => {
    return (
      isParent ||
      !!urlManager.options.parent?.subdomain ||
      !!(urlManager.options.parent as unknown as $TSFixMe)?.childrenProjects.length
    );
  }, [isParent, urlManager]);

  // Is the result in a sibling project? If so we generate links slightly differently (including version and child)
  const isResultInSibling = useMemo(() => {
    return !isParent && isEnterpise && resultSubdomain !== urlManager.options.project?.subdomain;
  }, [isParent, resultSubdomain, isEnterpise, urlManager.options]);

  // Let's create a new link!
  let url = '';
  // Remove existing hash so that we can replace it with specific hash from our search result
  if (window?.location?.hash) url = urlManager.remove('hash', window.location.href);
  // Attach full search result link to the existing url
  url = urlManager.upsert('slugs', internalLink.split('/'), url);

  // Only prefix hit URLs if we're on a normal enterprise group (not single project enterprise)
  // If we are, we'll need to take into account sibling names and versions for correct redirect routing
  if (isEnterpise && !isSingleProjectEnterpise) {
    // Add sibling path
    url = urlManager.upsert('child', resultSubdomain, url);
    // Replace the version from the path for sibling hits.
    if (urlManager.options.version && isResultInSibling) {
      url = urlManager.remove('version', url);
      url = urlManager.upsert('version', versionPath, url);
    }
  }

  return url;
}
