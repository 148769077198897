import useEnvInfo from '@core/hooks/useEnvInfo';

/**
 * This function generates a meta key combo based on the client OS.
 * Will combine ⌘ + key for Mac, ^ + key for Windows.
 * @param  {String} key   The key to be combined with the meta key.
 */

const useMeta = (key = '') => {
  /**
   * TODO: RM-883: this client platform check could be passed down from the server instead by
   * examining the request, which would make this check better for first render via SSR.
   *
   * Additionally, the navigator.platform is deprecated and we should update to navigator.userAgentData.platform
   * See https://erikmartinjordan.com/navigator-platform-deprecated-alternative
   */
  const { isClient } = useEnvInfo();
  const isMac = isClient && navigator?.platform && /Mac|iPad|iPhone/i.test(navigator.platform);
  return `${isMac ? '⌘' : 'CTRL-'}${key}`;
};

export default useMeta;
