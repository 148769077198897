import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useLocation, NavLink } from 'react-router-dom';

import { AppMetaContext, EnterpriseParentContext, ProjectContext } from '@core/context';
import useFeatureOptions from '@core/hooks/useFeatureOptions';
import { useProjectStore } from '@core/store';

import { SectionGateBadge } from '@routes/SuperHub/Layout/PlanAccess/SectionGate';

import Dropdown from '@ui/Dropdown';
import Flex from '@ui/Flex';
import Menu from '@ui/Menu';

import classes from '../style.module.scss';
import { useNavLinks } from '../utils/hooks';

const isNavItemActive = (item, pathname) => pathname === item?.url || !!pathname?.split('/')?.[1]?.includes(item.type);

const PageCategory = ({ appMeta, children, featureOptions }) => (
  <button
    className={`rm-Header-link rm-Header-bottom-link ${classes.NavItem} Button Button_slate_text Button_md`}
    type="button"
  >
    <span>
      {children || [
        featureOptions?.[appMeta?.type],
        appMeta?.type === 'custompage' && appMeta.title,
        appMeta?.type === '404' && 'Page Not Found',
      ]}
    </span>
    <i className={`${classes['NavItem-chevron']} icon-chevron-down`} />
  </button>
);

const NavLinkWrapper = ({ className = '', classOverride = '', children, isMobile = false, item, ...props }) => {
  const { project } = useContext(ProjectContext);

  const isLanding = item.type === 'home' || item.type === 'landing';

  // Data for custom pages is stored somewhat funkily, so this handles that
  if (item.type === 'custompage') item.url = `/page/${item.page}`;

  // Redir homepage links for a single project enterprise child to the GLP.
  const useParentLP = isLanding && !!project?.flags?.singleProjectEnterprise && project?.siblings?.length === 1;

  // If the item is a URL or user Log In link, use an old-school anchor tag
  const TagType = item.type === 'url' || item.type === 'user' || useParentLP ? 'a' : NavLink;

  const isNavLink = TagType === NavLink;
  const openLinkInNewTab = isMobile && item?.url?.startsWith('http');

  return (
    <TagType
      className={classOverride || `Button Button_md ${className}`}
      exact={isNavLink ? isLanding : undefined}
      href={isLanding ? '/' : item?.url}
      rel={openLinkInNewTab ? 'noopener' : undefined}
      target={openLinkInNewTab ? '_blank' : '_self'}
      to={isLanding ? '/' : item?.url || `/${item.type}`}
      {...props}
    >
      {children}
    </TagType>
  );
};

const NavLinksPills = ({ navLinksList }) => {
  return navLinksList.map(item => (
    <NavLinkWrapper
      key={`${item.type}-${item.text}`}
      className={`rm-Header-link rm-Header-bottom-link Button_slate_text Header-bottom-link_mobile ${
        !item.enabled ? classes.NavItem_muted : ''
      }`}
      item={item}
    >
      <SectionGateBadge isEnabled={item.enabled} section={item.type} />
      <span>{item.title || item.text}</span>
    </NavLinkWrapper>
  ));
};

const NavLinksDropdown = ({ featureOptions, navLinksList }) => {
  const { pathname } = useLocation();
  const ent = useContext(EnterpriseParentContext);
  const appMeta = useContext(AppMetaContext);

  // Show page titles for specific pages (hiding title on root route for changelogs and discussions)
  const pagesWithTitles = ['changelog', 'docs', 'discuss'];
  const hidePageTitle =
    pathname === '/changelog' ||
    pathname === '/discuss' ||
    (appMeta.type === 'changelog' && !pathname.startsWith('/changelog')) ||
    (appMeta.type === 'discuss' && !pathname.startsWith('/discuss'));

  const onGLP = ent.isParent && pathname === '/';

  // For some reason, Tippy won't hide itself when inner content has been clicked unless it's rendered in an element separate
  // from the return statement. I have no idea why this is, but please leave it this way unless you've figured out how to fix it!
  const TippyDropdown = () => (
    <Dropdown justify="start">
      <div>
        <PageCategory appMeta={appMeta} featureOptions={featureOptions}>
          {!!onGLP && 'Home'}
        </PageCategory>
      </div>
      <Menu>
        {navLinksList.map(item => (
          <NavLinkWrapper
            key={`${item.type}-${item.text}`}
            classOverride={`Menu-Item Menu-Item-alignIcon_left Menu-Item_link ${
              (onGLP && item.type === 'landing') || isNavItemActive(item, pathname) ? 'Menu-Item_active' : ''
            }`}
            item={item}
          >
            <Flex align="center" gap="xs" justify="start">
              <SectionGateBadge className={classes['NavItem-badge']} isEnabled={item.enabled} section={item.type} />
              <span className={classes['NavItem-text']}>{item.title || item.text}</span>
            </Flex>
          </NavLinkWrapper>
        ))}
      </Menu>
    </Dropdown>
  );

  return (
    <React.Fragment>
      <TippyDropdown />
      {pagesWithTitles.includes(appMeta.type) && !hidePageTitle && (
        <span className={`rm-Header-link rm-Header-bottom-link ${classes.NavItem} ${classes.NavItem_inactive}`}>
          {appMeta.title}
        </span>
      )}
    </React.Fragment>
  );
};

const NavLinks = ({ type }) => {
  const { project } = useContext(ProjectContext);
  const [subheaderLayout] = useProjectStore(s => [s.data.appearance.navigation.subheader_layout]);
  const { modules } = project;
  const featureOptions = useFeatureOptions(modules);
  const { base: baseLinks, extra: extraLinks } = useNavLinks();
  const navLinksList = type && type === 'extra' ? extraLinks : baseLinks;
  if (subheaderLayout === 'links' || type === 'extra') return <NavLinksPills navLinksList={navLinksList} />;

  // Default nav layout is dropdown
  return <NavLinksDropdown featureOptions={featureOptions} navLinksList={navLinksList} />;
};

PageCategory.propTypes = {
  appMeta: PropTypes.shape({
    title: PropTypes.string,
    type: PropTypes.string,
  }),
  children: PropTypes.node,
  featureOptions: PropTypes.object,
};

NavLinkWrapper.propTypes = {
  classOverride: PropTypes.string,
  isMobile: PropTypes.bool,
  item: PropTypes.shape({
    page: PropTypes.string,
    text: PropTypes.string,
    type: PropTypes.string,
    url: PropTypes.string,
  }),
};

NavLinks.propTypes = {
  type: PropTypes.string,
};

NavLinksPills.propTypes = {
  navLinksList: PropTypes.array,
};

NavLinksDropdown.propTypes = {
  featureOptions: PropTypes.object,
  navLinksList: PropTypes.array,
};

export default NavLinks;
export { isNavItemActive, NavLinkWrapper };
