import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import classes from './style.module.scss';
import './style.scss';

const Flyout = ({ children, className, toggleButton }) => {
  const [isOpen, setIsOpen] = useState(false);
  const flyoutRef = useRef();
  const history = useHistory();

  useEffect(() => {
    return history.listen(() => {
      setIsOpen(false);
    });
  }, [history]);

  useEffect(() => {
    const handleOutsideClick = e => {
      const flyout = 'current' in flyoutRef && flyoutRef.current;
      if (flyout && isOpen && !flyout.contains(e.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    return () => document.removeEventListener('mousedown', handleOutsideClick);
  }, [isOpen]);

  return (
    <div className={className}>
      {!!toggleButton &&
        React.cloneElement(toggleButton, {
          onClick: () => {
            setIsOpen(!isOpen);
          },
        })}
      <div className={isOpen ? classes.Flyout_overlay : ''}>
        <div
          ref={flyoutRef}
          className={`${classes.Flyout} ${classes[`Flyout_${isOpen ? 'opened' : 'closed'}`]} rm-Flyout`}
          data-testid="flyout"
        >
          {!!children && React.cloneElement(children, { setIsOpen })}
        </div>
      </div>
    </div>
  );
};

Flyout.propTypes = {
  config: PropTypes.object,
  lang: PropTypes.string,
  toggleButton: PropTypes.element,
  version: PropTypes.object,
};

export default Flyout;
