import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';

import { BaseUrlContext, ProjectContext, UserContext } from '@core/context';
import useUserPermissions from '@core/hooks/useUserPermissions';
import { useProjectStore, useSuperHubStore } from '@core/store';

import { SectionGateBadge } from '@routes/SuperHub/Layout/PlanAccess/SectionGate';

import Select from '@ui/Select';

import classes from '../../style.module.scss';
import { useNavLinks, useVersions, useLanguages, useSiblings } from '../../utils/hooks';
import { NavLinkWrapper } from '../NavLinks';

const onNavigate = e => {
  const index = e?.target?.selectedIndex;
  window.location.assign(e.target[index]?.dataset?.url);
};

const MobileLinkItem = ({ children, item, setIsOpen }) => (
  <NavLinkWrapper
    classOverride={`rm-MobileFlyout-item ${classes['NavItem-item']} ${classes['NavItem-item_mobile']} ${
      !item.enabled ? classes['NavItem_dropdown-muted'] : ''
    }`}
    isMobile
    item={item}
    onClick={() => setIsOpen(false)}
  >
    {children}
  </NavLinkWrapper>
);

const MobileUserItem = ({ item }) => {
  const { text, type } = item;
  const enableSuggestedEdits = useProjectStore(s => s.data.suggested_edits === 'enabled');
  const isSuperHubAdmin = useSuperHubStore(s => s.isSuperHubAdmin);
  const { permissions } = useContext(UserContext) || {};
  const { pathname } = useLocation();
  const { isAdminUser } = useUserPermissions();

  if (!permissions) item.url = `/login?redirect_uri=${pathname}`;

  const adminUser = {
    label: 'Admin Panel',
    path: '/dash',
    value: 'Admin Panel',
  };
  const suggestedEdits = {
    label: 'Suggested Edits',
    path: '/suggested-edits',
    value: 'Suggested Edits',
  };
  const logout = {
    label: 'Log Out',
    path: `/logout?redirect_uri=${pathname}`,
    value: 'Log Out',
  };

  const userOptions = [
    { label: text || 'Account', disabled: true, value: 'Account' },
    isAdminUser && !isSuperHubAdmin && adminUser,
    enableSuggestedEdits && !isSuperHubAdmin && suggestedEdits,
    logout,
  ].filter(opt => opt);

  return !permissions ? (
    <MobileLinkItem key={`${type}-${text}`} item={item}>
      Log In
    </MobileLinkItem>
  ) : (
    <Select
      className={`${classes['NavItem-item']} ${classes['NavItem-item_select']} ${classes['NavItem-item_mobile']}`}
      onChange={onNavigate}
      options={userOptions}
      value={'Account'}
    />
  );
};

const MenuItemsMobile = ({ setIsOpen }) => {
  const { base, extra } = useNavLinks();

  const { project } = useContext(ProjectContext);
  const { pathname } = useLocation();
  const isGLP = ['/', ''].includes(pathname) && project.childrenProjects?.length;

  const navLinks = isGLP ? extra : [...base, ...extra];
  return navLinks.map(item => {
    return typeof item.text !== 'string' ? null : (
      <MobileLinkItem key={`${item.type}-${item.text}`} item={item} setIsOpen={setIsOpen}>
        <SectionGateBadge className={classes['NavItem-badge']} isEnabled={item.enabled} section={item.type} />
        <span className={classes['NavItem-text']}>{item.text}</span>
      </MobileLinkItem>
    );
  });
};

const VersionPickerMobile = ({ lang, version }) => {
  const { project } = useContext(ProjectContext);
  let activeVersion = '';
  const versionsList = useVersions({ lang, version });
  const versionsOptions = versionsList.map(v => {
    if (v.active) activeVersion = v.version;
    return { label: `v${v.version}`, path: v.path, value: v.version };
  });

  return project.versions.length === 1 ? (
    <div className={`${classes['NavItem-item']} ${classes['NavItem-item_inactive']}`}>v{version.version}</div>
  ) : (
    <Select
      className={`${classes['NavItem-item']} ${classes['NavItem-item_select']} ${classes['NavItem-item_mobile']}`}
      onChange={onNavigate}
      options={versionsOptions}
      value={activeVersion}
    />
  );
};

const TranslationPickerMobile = ({ lang, version }) => {
  let activeLanguage = '';
  const languagesList = useLanguages({ lang, version });
  const languagesOptions = languagesList.reduce((result, l) => {
    if (!l) return result;
    if (l.active) activeLanguage = l.name;
    result.push({ label: l.name, path: l.path, value: l.name });
    return result;
  }, []);

  return (
    <Select
      className={`${classes['NavItem-item']} ${classes['NavItem-item_select']} ${classes['NavItem-item_mobile']}`}
      onChange={onNavigate}
      options={languagesOptions}
      value={activeLanguage}
    />
  );
};

const ProjectPickerMobile = ({ lang }) => {
  const baseUrl = useContext(BaseUrlContext);
  let activeSibling = '';
  const siblingsList = useSiblings({ baseUrl, lang });
  const siblingsOptions = siblingsList?.map(sib => {
    if (sib.active) activeSibling = sib.name;
    return { label: sib.name, path: sib.path, value: sib.name };
  });

  return (
    <Select
      className={`${classes['NavItem-item']} ${classes['NavItem-item_select']} ${classes['NavItem-item_mobile']}`}
      onChange={onNavigate}
      options={siblingsOptions}
      value={activeSibling}
    />
  );
};

VersionPickerMobile.propTypes = {
  lang: PropTypes.string,
  version: PropTypes.object,
};

TranslationPickerMobile.propTypes = VersionPickerMobile.propTypes;

ProjectPickerMobile.propTypes = {
  lang: PropTypes.string,
};

MobileLinkItem.propTypes = {
  item: PropTypes.shape({
    enabled: PropTypes.bool,
  }),
  setIsOpen: PropTypes.func,
};

MobileUserItem.propTypes = {
  item: PropTypes.shape({
    text: PropTypes.string,
    type: PropTypes.string,
    url: PropTypes.string,
  }),
};

export {
  MobileLinkItem,
  MobileUserItem,
  MenuItemsMobile,
  VersionPickerMobile,
  TranslationPickerMobile,
  ProjectPickerMobile,
};
