import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import SearchTrigger from '@Hub/Search/Trigger';
import SearchBert from '@Hub/SearchBert';

import { AppMetaContext, EnterpriseParentContext, ProjectContext } from '@core/context';
import useMeta from '@core/hooks/useMeta';

import classes from '../../style.module.scss';
import MobileSubnav from '../mobile/Subnav';
import NavLinks from '../NavLinks';
import ProjectPicker from '../ProjectPicker';
import TranslationDropdown from '../TranslationDropdown';
import VersionDropdown from '../VersionDropdown';

const AppSubnavItems = ({ baseUrl, lang, langFull, version }) => {
  const ent = useContext(EnterpriseParentContext);
  const showDetails = !ent.isParent || ent.singleParent;

  return (
    <nav className={classes['Header-left']}>
      <ProjectPicker baseUrl={baseUrl} lang={lang} />
      {!!showDetails && [
        <TranslationDropdown key={'AppSubNav--translations'} lang={lang} langFull={langFull} version={version} />,
        <VersionDropdown key={'AppSubNav--versions'} lang={lang} version={version} />,
      ]}
      {!!showDetails && <NavLinks type="base" />}
      <NavLinks type="extra" />
    </nav>
  );
};

const AppSubnav = ({ theme, toggleSearch, ...props }) => {
  const ent = useContext(EnterpriseParentContext);
  const appMeta = useContext(AppMetaContext);
  const { project } = useContext(ProjectContext);
  const { appearance } = project;
  const { pathname, search } = useLocation();
  const [shortcut, setShortcut] = useState('');

  const meta = useMeta('K');
  const isLP = appMeta.type === 'home' || pathname === '/';

  // After initial render, use shortcut that is dependent on client being load
  // Note: this prevents server/client hydration warning
  useEffect(() => {
    setShortcut(meta);
  }, [meta]);

  const onLandingPageWithSearch =
    (appMeta.type === 'home' || pathname === '/') && appearance?.promos?.[0]?.extras?.type === 'search';

  const hasCustomBackground = appearance.header.style === 'custom' && appearance.header.img.length;
  const additionalSearchClasses = !(appearance.header.style === 'gradient' || hasCustomBackground)
    ? `rm-Header-search_feather ${classes['Header-search_feather']}`
    : '';

  const owlbotTrial = new URLSearchParams(search).get('owlbotTrial');
  const owlbotEnabled = project.owlbot.enabled || owlbotTrial === 'true';

  return (
    <div
      className={`${classes['Header-bottom']} rm-Header-bottom ${
        ent.isParent && isLP ? `rm-Header-bottom_withProjects ${classes['Header-bottom_withProjects']}` : ''
      } ${owlbotEnabled ? `rm-Header-bottom_withOwlbot ${classes['Header-bottom_withOwlbot']}` : ''}`}
    >
      <div className="rm-Container rm-Container_flex">
        <AppSubnavItems {...props} />
        <MobileSubnav />
        {/* Checking there is a valid searchApiKey cause the page crashes otherwise */}
        {!onLandingPageWithSearch && !!props.search.searchApiKey && !owlbotEnabled && (
          <SearchTrigger
            className={`rm-Header-search ${classes['Header-search']} ${additionalSearchClasses}`}
            toggleSearch={toggleSearch}
          >
            <div className="rm-SearchToggle-icon icon-search1" />
            <div className={`${classes['Header-search-placeholder']} rm-SearchToggle-placeholder`}>Search</div>
            <div className="rm-SearchToggle-shortcut">{shortcut}</div>
          </SearchTrigger>
        )}
        {!!owlbotEnabled && !!props.search.searchApiKey && (
          <div
            className={`rm-Header-search ${classes['Header-search']} ${classes['Header-search_owlbot']} ${additionalSearchClasses}`}
          >
            <SearchBert
              childSubdomain={project.parent ? project.subdomain : undefined}
              toggleSearch={toggleSearch}
              {...props}
            />
          </div>
        )}
      </div>
    </div>
  );
};

AppSubnav.propTypes = {
  isModalOpen: PropTypes.bool,
  lang: PropTypes.string,
  search: PropTypes.object,
  theme: PropTypes.oneOf(['auto', 'dark', 'light']),
  toggleSearch: PropTypes.func,
  version: PropTypes.object,
};

AppSubnavItems.propTypes = {
  baseUrl: PropTypes.string,
  lang: PropTypes.string,
  langFull: PropTypes.string,
  version: PropTypes.object,
};

export default AppSubnav;
