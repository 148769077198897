import type { SearchResults as SearchResultsType } from '@readme/api/src/mappings/search/types';

import React from 'react';

import type { IProjectMetadata } from '@Hub/Search/types';

import useClassy from '@core/hooks/useClassy';
import { useSearchStore } from '@core/store';

import Icon from '@ui/Icon';
import Title from '@ui/Title';

import Result from './Result';
import styles from './style.module.scss';

interface SearchResultsProps {
  className?: string;
  // SearchBert is our mini search that appears before full modal
  isSearchBert?: boolean;
  limit?: number;
  onResultSelection: ({ link }: { link: string }) => void;
  projectMetadata: IProjectMetadata[];
  results: SearchResultsType;
}

const SearchResults = ({
  className,
  limit,
  onResultSelection,
  projectMetadata,
  results,
  isSearchBert = false,
}: SearchResultsProps) => {
  const bem = useClassy(styles, 'SearchResults');
  const [prompt, isLoading] = useSearchStore(store => [store.prompt, store.isLoading]);

  if (!prompt) {
    return (
      <div className={bem('rm-SearchModal-empty', '&', '_empty')}>
        {/* Keeping `icon icon-search` classes for custom CSS */}
        <Icon className="rm-SearchModal-empty-icon icon icon-search" name="search" size="md" />
        <Title className="rm-SearchModal-empty-text" level={6}>
          {prompt.length ? 'Keep' : 'Start'} typing to search…
        </Title>
      </div>
    );
  }

  if (!isLoading && results.total === 0) {
    return (
      <div className={bem('&', '_empty', className && `${className}-empty`)}>
        <div className={`${className}-empty-description`}>No search results for ‘{prompt}’.</div>
      </div>
    );
  }

  let visibleResults = results.data;

  if (limit) visibleResults = visibleResults.slice(0, limit);

  return (
    <div className={bem('&', isSearchBert && '_is-searchbert')}>
      {!isSearchBert && <div className={bem('-scroll-to', className && `${className}-scroll-to`)} />}
      <div aria-live="polite" className={bem('-list', className && `${className}-list`, 'notranslate')}>
        {visibleResults.map(result => (
          <Result
            key={`key-${result.uri}`}
            className={className}
            onResultSelection={onResultSelection}
            projectMetadata={projectMetadata}
            result={result}
          />
        ))}
      </div>
      {/* TODO: Post-beta: Pagination */}
      {/* {!limit && <Pagination />} */}
    </div>
  );
};

export default React.memo(SearchResults);
