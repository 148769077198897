/* eslint-disable no-useless-computed-key */
/* eslint-disable dot-notation */
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { ProjectContext } from '@core/context';
import { useProjectStore } from '@core/store';

import Button from '@ui/Button';
import Dropdown from '@ui/Dropdown';
import Flex from '@ui/Flex';
import Menu, { MenuItem } from '@ui/Menu';
import Tooltip from '@ui/Tooltip';

import classes from '../style.module.scss';
import { useVersions } from '../utils/hooks';

const VersionDropdown = ({ lang, version }) => {
  const { project } = useContext(ProjectContext);
  const versionsList = useVersions({ lang, version });

  const versionVisibility = useProjectStore(s => s.data.appearance.navigation.version);

  if (versionVisibility === 'disabled') return null;

  if (project.versions.length > 1) {
    return (
      <Dropdown className="rm-VersionDropdown" justify="start">
        <Button bem={{ ['slate_text']: true }} className="rm-Header-bottom-link">
          <span>v{version.version}</span>
          <i className="icon icon-chevron-down" />
        </Button>
        <Menu className="rm-VersionDropdown-menu">
          {versionsList.map(v => (
            <MenuItem key={v.version} active={version.version === v.version} href={v.path} TagName="a">
              <Flex className={classes['NavItem-item-flex']} gap="0" layout="col">
                <Flex align="center" className={classes['NavItem-item-flex']} gap="0">
                  <span className={classes['NavItem-item-flex']} title={v.version}>
                    v{v.version}
                  </span>
                  {!!v.is_deprecated && (
                    <span className={`${classes.VersionBadge} ${classes.VersionBadge_gray}`}>Deprecated</span>
                  )}
                  {!!v.is_beta && <span className={`${classes.VersionBadge} ${classes.VersionBadge_gray}`}>Beta</span>}
                  {!!v.is_stable && (
                    <span className={`${classes.VersionBadge} ${classes.VersionBadge_green}`}>Stable</span>
                  )}
                  {!!v.is_hidden && (
                    <Tooltip content="Only visible to ReadMe admins" placement="bottom">
                      <span
                        className={`icon-eye-off ${classes['NavItem-hidden']} ${classes['NavItem-hidden_version']}`}
                      />
                    </Tooltip>
                  )}
                </Flex>
                {!!v.codename && (
                  <div className={classes['NavItem-item-codename']} title={v.codename}>
                    {v.codename}
                  </div>
                )}
              </Flex>
            </MenuItem>
          ))}
        </Menu>
      </Dropdown>
    );
  }

  if (!Object.keys(version).length) return null;
  return (
    <span className={`${classes.NavItem} ${classes['NavItem_inactive']} rm-Header-bottom-link`}>
      v{version.version}
    </span>
  );
};

VersionDropdown.propTypes = {
  lang: PropTypes.string,
  version: PropTypes.object,
};

export default VersionDropdown;
