import React, { useState, useContext, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { ProjectContext, UserContext, ConfigContext } from '@core/context';
import useIsStagingViewer from '@core/hooks/useIsStagingViewer';

import Banner from '@ui/Banner';
import Button from '@ui/Button';
import Dropdown from '@ui/Dropdown';
import Flex from '@ui/Flex';
import HTTPStatus from '@ui/HTTPStatus';
import Menu from '@ui/Menu';
import MenuItem from '@ui/Menu/Item';

import classes from '../../style.module.scss';

interface StagingBannerProps {
  isDetachedProductionSite: boolean;
}

interface Project {
  flags: Record<string, unknown>;
  parent: {
    [key: string]: unknown;
    flags: Record<string, unknown>;
  };
  stable: Record<string, unknown>;
}
const StagingBanner = ({ isDetachedProductionSite }: StagingBannerProps) => {
  const user = useContext(UserContext) || {};
  const { project } = useContext(ProjectContext);
  const { parent, flags, stable } = project as Project;
  const { domainFull } = useContext(ConfigContext) as { domainFull: string };
  const { pathname } = useLocation();

  const selection = useMemo(() => {
    return isDetachedProductionSite ? 'Production' : 'Staging';
  }, [isDetachedProductionSite]);

  const [isStagingViewer, setIsStagingViewer] = useState(false);

  const generateUrl = (isStage = false) => {
    const { subdomain } = project as { subdomain: string };
    const redirect = `/v${stable.version}${pathname}`;
    const staging = `${isStage ? 1 : 0}`;

    return `${domainFull}/go/${subdomain}?redirect=${redirect}&staging=${staging}`;
  };

  const isStagingEnabled = parent ? parent.flags.staging : flags.staging;
  // Check if a user is a staging viewer
  const canViewStaging = useIsStagingViewer(user, project);

  const enviroments = {
    Production: generateUrl(),
    Staging: generateUrl(true),
  };

  useEffect(() => {
    setIsStagingViewer(canViewStaging);
  }, [canViewStaging]);

  // If staging isn't enabled, the user is an end user, or the new staging isn't enabled, don't show this banner
  return !!isStagingEnabled && !!isStagingViewer ? (
    <Banner className={classes['Header-staging']} icon="">
      <Flex align="center" justify="around">
        <Dropdown clickInToClose trigger="click">
          <Button className={classes['Header-staging-button']} dropdown ghost size="sm">
            <HTTPStatus iconOnly status={selection === 'Production' ? '2XX' : '3XX'} />
            {selection}
          </Button>
          <Menu className={classes['Header-staging-menu']}>
            {Object.keys(enviroments).map(type => (
              <MenuItem key={type}>
                <a
                  className={classes['Header-staging-link']}
                  href={enviroments[type as keyof typeof enviroments]}
                  rel="noreferrer"
                  target="_blank"
                >
                  <Flex align="center" className={classes['NavItem-item-flex']} layout="row">
                    {type}
                    {type === selection && <i className={`${classes['NavItem-item-check']} icon-check1`} />}
                  </Flex>
                </a>
              </MenuItem>
            ))}
          </Menu>
        </Dropdown>
      </Flex>
    </Banner>
  ) : null;
};

export default StagingBanner;
