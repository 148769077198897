import type { ISearchBox } from './types';

import React, { useEffect, useCallback, useState } from 'react';
import { useSearchBox } from 'react-instantsearch-hooks-web';

import useDebounced from '@core/hooks/useDebounced';
import { useSearchStore } from '@core/store';
import classy from '@core/utils/classy';

import Input from '@ui/Input';

import classes from './style.module.scss';

const SearchBox = ({ inputRef, isModalOpen, subdomain, onClickout }: ISearchBox) => {
  const [prevQuery, setPrevQuery] = useState('');
  const { refine, isSearchStalled } = useSearchBox();
  const [prompt, setPrompt] = useSearchStore(store => [store.prompt, store.updatePrompt]);

  const _onChange = useCallback(
    value => {
      refine(value);
    },
    [refine],
  );

  const onChange = useDebounced(_onChange, 300);

  useEffect(() => {
    // When the modal first opens, set focus to the searchbar
    if (isModalOpen && inputRef?.current) {
      inputRef.current.focus();
      // Also, if we have a saved prompt from our combined search view
      // We want to get the results for that prompt
      if (prompt) onChange(prompt);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);

  useEffect(() => {
    if (inputRef?.current) inputRef.current.focus();
  }, [inputRef]);

  const handleBlur = useCallback(
    event => {
      const { value: search } = event.currentTarget;

      if (subdomain && search !== prevQuery) {
        setPrevQuery(search);
        onClickout({ term: search });
      }
    },
    [subdomain, prevQuery, onClickout],
  );

  return (
    <div className={classes.SearchBox}>
      <Input
        ref={inputRef}
        aria-label="Search"
        autoCapitalize="off"
        autoComplete="off"
        autoCorrect="off"
        className={classy(classes, 'SearchBox-Input', isSearchStalled && 'SearchBox-Input_loading')}
        onBlur={handleBlur}
        onChange={e => {
          onChange(e.currentTarget.value);
          setPrompt(e.currentTarget.value);
        }}
        spellCheck="false"
        tabIndex={0}
        type="search"
        value={prompt || ''}
      />
      {!!isSearchStalled && <i className={classes['SearchBox-Spinner']} />}
    </div>
  );
};

export default React.memo(SearchBox);
